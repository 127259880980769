import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.inter = setInterval(() => {
      window.location.reload()
    },20000);

  }

  disconnect() {
    clearInterval(this.inter)
  }
}
